import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const SEO = (props) => {
  const { pathname } = useLocation() // aktullen Pfadnamen erhalten
  const { site } = useStaticQuery(query) // Daten der Query unten erhalten
   // entpacken der Variablen aus der Query
   // die Daten sind in site.siteMetadata gesprichert
   // den einzelnen Variablen z.B. defaultTitle wird dann die passende aus site.siteMetadata zugewiesen
   // zur Erinnerung: die siteMetadata stammen aus gatsby-config.js
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = site.siteMetadata
  // Definition der seo-Daten -> jeweils entweder aus den props oder, falls diese leer sind: aus den Standards siteMetadata von gatsby-config.js
  const seo = {
    title: props.title || defaultTitle,
    description: props.description || defaultDescription,
    image: `${siteUrl}${props.image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet title={seo.title}>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && <meta property="og:description" content={seo.description} />}
      {seo.image && <meta property="og:image" content={seo.image} />}
    </Helmet>
  )
}
export default SEO

// die propTypes sind nur zur "Sicherheit"; kann man auch weglassen
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle
        defaultDescription
        siteUrl
        defaultImage
      }
    }
  }
`
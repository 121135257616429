export const colors = {
  primary: "#8198b1",
}

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}

export const spacing = {
  contentMaxWidth: 960
}